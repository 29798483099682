<template> </template>

<script>

let { daum } = window;

export default {
    props: {
        value: { type: Object, default: () => ({ postcode: "", address: "" }) },
    },
    methods: {
        open() {
            if (daum?.Postcode) {
                new daum.Postcode({
                    oncomplete: (data) => {
                        let postcode = data?.zonecode;
                        let address = data?.roadAddress || data?.jibunAddress;
                        this.$emit("input", { postcode, address });
                        this.$emit("change", { postcode, address });
                    },
                }).open();
            }
        },
    },
};
</script>
