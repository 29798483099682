<template>
    <v-responsive class="pa-2">
        <v-row no-gutters>
            <v-col cols="12" md="6">
                <v-text-field v-model="member.username" v-bind="$attrs" label="아이디" persistent-placeholder hide-details class="mx-2"/>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6">
                <v-password-field v-model="member.password" v-bind="$attrs" label="비밀번호" persistent-placeholder hide-details class="mx-2 mt-4" />
            </v-col>
            <v-col cols="12" md="6">
                <v-password-field v-model="member.password2" v-bind="$attrs" label="비밀번호확인" persistent-placeholder hide-details class="mx-2 mt-4" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-btn outlined x-large width="100%" color="primary" class="mt-4" @click="$refs.kcpCert.open()"><v-icon class="mr-2 mt-1">{{ mdiCertificateOutline }}</v-icon>본인인증</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6">
                <v-text-field v-model="member.name" v-bind="$attrs" label="이름" persistent-placeholder hide-details readonly class="mx-2 mt-4" @click="$refs.kcpCert.open()" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="9">
                <v-row no-gutters align="center">
                    <v-col>
                        <v-text-field v-model="member.phone1" v-bind="$attrs" label="연락처" persistent-placeholder hide-details readonly class="mx-2 mt-4" @click="$refs.kcpCert.open()" />
                    </v-col>
                    <v-icon small class="mt-4">mdi-minus</v-icon>
                    <v-col>
                        <v-text-field v-model="member.phone2" v-bind="$attrs" hide-details maxlength="4" readonly class="mx-2 mt-4" @click="$refs.kcpCert.open()"/>
                    </v-col>
                    <v-icon small class="mt-4">mdi-minus</v-icon>
                    <v-col>
                        <v-text-field v-model="member.phone3" v-bind="$attrs" hide-details maxlength="4" readonly class="mx-2 mt-4" @click="$refs.kcpCert.open()"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="9">
                <v-row no-gutters align="center">
                    <v-col>
                        <v-text-field v-model="member.email1" v-bind="$attrs" label="이메일" persistent-placeholder hide-details class="mx-2 mt-4" />
                    </v-col>
                    <v-col cols="auto" class="pa-0">
                        <v-icon small class="mt-4">mdi-at</v-icon>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="member.email2" v-bind="$attrs" hide-details class="mx-2 mt-4"/>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-select v-model="member.email3" v-bind="$attrs" :items="prependEmails" item-text="text" item-value="value" hide-details class="mx-2 mt-md-4" @input="member.email2 = member.email3"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="6">
                <v-row no-gutters align="center">
                    <v-col>
                        <v-text-field v-model="member.postcode" v-bind="$attrs" label="우편번호" persistent-placeholder hide-details readonly class="mx-2 mt-4" @click="$refs.daumPostcode.open()" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="12">
                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto">
                        <v-text-field v-model="member.address1" v-bind="$attrs" label="기본주소" persistent-placeholder hide-details readonly class="mx-2 mt-4" @click="$refs.daumPostcode.open()"/>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <v-text-field v-model="member.address2" v-bind="$attrs" label="상세주소" persistent-placeholder hide-details class="mx-2 mt-4"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="12">
                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto">
                        <v-checkbox v-model="member.smsEnabled" v-bind="$attrs" label="SMS 수신동의" persistent-placeholder hide-details class="mx-2 mt-4"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-btn width="100%" outlined x-large color="primary" class="mt-6" @click="join">회원가입</v-btn>

        <kcp-cert ref="kcpCert" @input="certify"></kcp-cert>
        <daum-postcode ref="daumPostcode" @input="({ postcode, address }) => { member.postcode = postcode; member.address1 = address; }" />
        <!-- <daum-postcode-legacy ref="daumPostcode" @input="({ postcode, address }) => { member.postcode = postcode; member.address1 = address; }" /> -->
    </v-responsive>
</template>
<script>
import api from "@/api";
import { mdiCertificateOutline  } from "@mdi/js"
import CryptoAES from "@/plugins/crypto-aes";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import DaumPostcodeLegacy from '@/components/plugins/daum/daum-postcode-legacy.vue';

export default {
    components: {
        KcpCert,
        VPasswordField,
        DaumPostcode,
        DaumPostcodeLegacy
    },
    props: ["value"],
    data(){
        return {
            mdiCertificateOutline,

            member: this.value,

            appendPhones: ["010", "011", "016", "017", "019"],
            prependEmails: [
                { text: ":: 직접입력 ::", value: null },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ]
        }
    },
    methods: {
        validate(){
            try{
                let member = this.member;
                if(!member.username) throw new Error("아이디를 입력해주세요");
                if(member.username.match(/[^0-9a-zA-Z]/g) || member.username.length < 4 || 20 < member.username.length) throw new Error("아이디는 영문숫자 4~20자리로 입력해주시기 바랍니다");
                if(!member.password || !member.password2) throw new Error("비밀번호를 입력해주세요");
                if(member.password.match(/ /g) || member.password2.match(/ /g)) throw new Error("비밀번호에 공백은 제외해서 입력해주세요");
                if(member.password != member.password2) throw new Error("비밀번호가 일치하지 않습니다");
                if(0 <= member.password.indexOf(member.username) || !member.password.match(/[0-9]/) || !member.password.match(/[a-zA-Z]/) || !member.password.match(/[`~!@#$%^&*()\-_=+\[\]{};:'",.<>\/\?]/) || member.password.length < 8 || 20 < member.password.length ) throw new Error("비밀번호는 회원 아이디와 동일하지 않아야 하며 영어+숫자+특수문자 혼합 문자로 최소 8~20자리입니다");
                if(!member.name) throw new Error("본인인증을 해주세요");
                if(!member.phone1 || !member.phone2 || !member.phone3) throw new Error("본인인증을 해주세요");
                if(!member.email1 || !member.email2) throw new Error("이메일을 입력해주세요");
                if(member.email1.match(/[@'"`(){}\[\]]/) || member.email1.substr(-1) == '.') throw new Error("이메일 양식을 확인해주세요")
                if(!member.email2.match(/[.]/) || member.email2.match(/[^0-9a-zA-Z.]/)) throw new Error("이메일 양식을 확인해주세요")
                if(!member.postcode) throw new Error("우편번호를 입력해주세요");
                if(!member.address1) throw new Error("기본주소를 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        certify({ name, phone, birthday, ci }){
            this.member.name = name;
            
            let [phone1, phone2, phone3] = phone?.phoneNumberFormat?.()?.split?.('-') || [];
            this.member.phone1 = phone1;
            this.member.phone2 = phone2;
            this.member.phone3 = phone3;

            this.member.birthday = birthday;

            this.member.ci = ci;
        },
        async join(){
            if(this.validate()){
                let member = this.member;
                let { member: me } = await api.v1.members.post({
                    ...member,
                    password: CryptoAES.encrypt(member.password),
                    password2: null,
                    phone: [member.phone1, member.phone2, member.phone3].join('-'),
                    email: [member.email1, member.email2].join('@')
                });

                this.$set(this.member, "vaccount", me.vaccount);
                this.$emit("next");
            }
        }
    },
    watch: {
        value(){
            this.member = this.value;
        }
    }
}
</script>