var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "pa-2"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "mx-2",
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.member.username,
      callback: function ($$v) {
        _vm.$set(_vm.member, "username", $$v);
      },
      expression: "member.username"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-password-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.member.password,
      callback: function ($$v) {
        _vm.$set(_vm.member, "password", $$v);
      },
      expression: "member.password"
    }
  }, 'v-password-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-password-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "비밀번호확인",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.member.password2,
      callback: function ($$v) {
        _vm.$set(_vm.member, "password2", $$v);
      },
      expression: "member.password2"
    }
  }, 'v-password-field', _vm.$attrs, false))], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "x-large": "",
      "width": "100%",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.kcpCert.open();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2 mt-1"
  }, [_vm._v(_vm._s(_vm.mdiCertificateOutline))]), _vm._v("본인인증")], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.kcpCert.open();
      }
    },
    model: {
      value: _vm.member.name,
      callback: function ($$v) {
        _vm.$set(_vm.member, "name", $$v);
      },
      expression: "member.name"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.kcpCert.open();
      }
    },
    model: {
      value: _vm.member.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.member, "phone1", $$v);
      },
      expression: "member.phone1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-icon', {
    staticClass: "mt-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-minus")]), _c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "hide-details": "",
      "maxlength": "4",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.kcpCert.open();
      }
    },
    model: {
      value: _vm.member.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.member, "phone2", $$v);
      },
      expression: "member.phone2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-icon', {
    staticClass: "mt-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-minus")]), _c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "hide-details": "",
      "maxlength": "4",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.kcpCert.open();
      }
    },
    model: {
      value: _vm.member.phone3,
      callback: function ($$v) {
        _vm.$set(_vm.member, "phone3", $$v);
      },
      expression: "member.phone3"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.member.email1,
      callback: function ($$v) {
        _vm.$set(_vm.member, "email1", $$v);
      },
      expression: "member.email1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    staticClass: "mt-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-at")])], 1), _c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.member.email2,
      callback: function ($$v) {
        _vm.$set(_vm.member, "email2", $$v);
      },
      expression: "member.email2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "mx-2 mt-md-4",
    attrs: {
      "items": _vm.prependEmails,
      "item-text": "text",
      "item-value": "value",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.member.email2 = _vm.member.email3;
      }
    },
    model: {
      value: _vm.member.email3,
      callback: function ($$v) {
        _vm.$set(_vm.member, "email3", $$v);
      },
      expression: "member.email3"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "우편번호",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.daumPostcode.open();
      }
    },
    model: {
      value: _vm.member.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.member, "postcode", $$v);
      },
      expression: "member.postcode"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "기본주소",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.daumPostcode.open();
      }
    },
    model: {
      value: _vm.member.address1,
      callback: function ($$v) {
        _vm.$set(_vm.member, "address1", $$v);
      },
      expression: "member.address1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "상세주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.member.address2,
      callback: function ($$v) {
        _vm.$set(_vm.member, "address2", $$v);
      },
      expression: "member.address2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    staticClass: "mx-2 mt-4",
    attrs: {
      "label": "SMS 수신동의",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.member.smsEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.member, "smsEnabled", $$v);
      },
      expression: "member.smsEnabled"
    }
  }, 'v-checkbox', _vm.$attrs, false))], 1)], 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "width": "100%",
      "outlined": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.join
    }
  }, [_vm._v("회원가입")]), _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "input": _vm.certify
    }
  }), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "input": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.member.postcode = postcode;
        _vm.member.address1 = address;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }